import { CircularProgress, css, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSessionStore } from 'session/useSessionStore';
import { useSeismicStore } from '../stores/useSeismicStore';
import { IColorbar } from '../models/interfaces/IColorbar';
import { useAPI } from 'hooks/useAPI';
import { useMapStore } from '../stores/useMapStore';

const style = css({
    maxWidth: '10rem',
});

const itemStyle = css({
    display: 'flex',
    flexDirection: 'row',
    fontSize: '14px',
    alignItems: 'center',

    '& .label': {
        marginLeft: 8,
        color: '#ffffff'
    }
});

const imageStyle = css({
    height: '1rem',
    width: '1rem'
});

export function SeismicColorbarSelect () {
    const useVelocityColorbars = useSeismicStore(state => state.useVelocityColorbars);

    // const { isLoading, execute, data } = useAPI<IColorbar[]>('/Seismic/Colorbar/Available');
    const staticColors:IColorbar[] = useMemo(() =>
        [
            {
                Token: '36403263-67cb-4811-851b-bdda032cafa2',
                Label: 'Grayscale',
                Name: 'binary',
                Picture: 'https://filemanager.geopostenergy.com/white_black_200_colorbar.jpg',
                Background: '#7F7F7F'
            },
            {
                Token: '3bc1ddc4-bcfe-4d1a-86ca-91708ca6cd53',
                Label: 'RWB',
                Name: 'seismic',
                Picture: 'https://filemanager.geopostenergy.com/12f0403b465902ac298631ef1bb968041703116963424.png',
                Background: '#FFFFFF'
            },
            {
                Token: '2a68b614-7cc9-4a8b-bf6e-afdce399005f',
                Label: 'RWG',
                Name: 'RdGy',
                Picture: 'https://filemanager.geopostenergy.com/9c031a8d7de010afdf4d72f9fd806af91708520684130.jpg',
                Background: '#FBFBFB'
            },
            {
                Token: 'jet',
                Name: 'jet',
                Label: 'Jet',
                Picture: 'https://filemanager.geopostenergy.com/30164128316b2fabc5ca647ad48388be1729019016024.jpeg',
                Background: '#7dff77'
            },
        ], [useVelocityColorbars]);
    const { setBackgroundColor } = useMapStore(state => ({
        setBackgroundColor: state.setBackgroundColor
    }));

    const { setColorbar, colorbar } = useSeismicStore(state => ({
        colorbar: state.colorbar,
        setColorbar: state.setColorbar
    }));

    const { tenantConfig } = useSessionStore(state => ({
        tenantConfig: state.tenantConfiguration,
    }));

    const [loadedDefault, setLoadedDefault] = useState(false);

    useEffect(() => {
        if (tenantConfig && !loadedDefault) {
            selectDefaultByTenant();
        }
    }, [tenantConfig]);

    const handleChange = (event: SelectChangeEvent) => {
        const tokenSelected = event.target.value as string;
        const colorBar = staticColors?.find(x => x.Token === tokenSelected);

        if (colorBar) {
            setBackgroundColor(colorBar.Background);
            setColorbar(colorBar);
        }
    };

    const selectDefaultByTenant = () => {
        const nameDefault = tenantConfig?.viewer2DDefaults?.color_bar_name;
        const colorBar = staticColors?.find(x => x.Name.toLowerCase() === nameDefault?.toLowerCase());

        if (colorBar){
            setBackgroundColor(colorBar.Background);
            setColorbar(colorBar);
        }

        setLoadedDefault(true);
    };

    return (
        <Fragment>
            <FormControl fullWidth css={style}>
                <Select
                    defaultValue='976eba63-1a53-42a6-9e85-46049d8886f8'
                    value={colorbar?.Token}
                    onChange={handleChange}
                    style={{ fontSize: '14px'}}
                >
                    {staticColors && staticColors.map(colorbar => (
                        <MenuItem value={colorbar.Token} key={colorbar.Token} >
                            <div className='item' css={itemStyle}>
                                <img className='image' src={colorbar.Picture} css={imageStyle}/>
                                <span className='label'>{colorbar.Label}</span>
                            </div>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Fragment>
    );
}