import { create } from 'zustand';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Coordinate } from 'ol/coordinate';
import ImageLayer from 'ol/layer/Image';
import ImageStatic from 'ol/source/ImageStatic';
import { Circle, LineString, Point } from 'ol/geom';

import { ISurveyMetadata } from 'features/seismic/models/interfaces/ISurveyMetadata';
import { IColorbar } from '../models/interfaces/IColorbar';
import { SeismicCalculator } from '../models/classes/SeismicCalculator';
import { IRelatedData } from '../models/interfaces/IRelatedData';
import { ILineString } from '../models/interfaces/ILineString';
import { ISeismicRequirement } from '../models/interfaces/ISeismicRequirement';
import { Survey2DLines } from '../models/interfaces/I2DLines';

export interface ISeismicStore {
    navbarLoading: boolean;
    setNavbarLoading: (value: boolean) => void;
    lines2D: Survey2DLines[] | null;
    calculator: SeismicCalculator | null;
    createWellInMiniMap: VectorLayer<VectorSource>[];
    colorbar: IColorbar;
    gain: number;
    genericCutsWithoutPermission: { [wellId: string]: string[] };
    geometryLine: ILineString | null;
    hasErrorOnGeometry: boolean;
    absoluteGain: IAbsoluteGain;
    isLoading: boolean;
    isInverted: boolean;
    isAntialiasApplied: boolean;
    isReLoadingLineGeometry: boolean;
    miniMapWells: IMiniMapWells;
    rangeTrace: IRangeTrace;
    removeWellInMiniMap: VectorLayer<VectorSource>[];
    restrict: ISeismicRequirement | null;
    resetWellRelatedData: () => void;
    resetWellRelatedDataTextLayers: () => void;
    scale: IScale;
    setLines2D: (value: Survey2DLines[]) => void;
    setAbsoluteGain: (value: IAbsoluteGain) => void;
    setCalculator: (value: SeismicCalculator) => void;
    setColorbar: (value: IColorbar) => void;
    setCreateWellInMiniMap: (layer: VectorLayer<VectorSource>[]) => void;
    setDefineWellLayers: (value: IWellLayers) => void;
    setGain: (value: number) => void;
    setGenericCutsWithoutPermission: (value: { [wellId: string]: string[] }) => void;
    setGeometryLine: (geometryLine: ILineString | null) => void;
    setIsLoading: (value: boolean) => void;
    setHasErrorOnGeometry: (value: boolean) => void;
    setIsInverted: (value: boolean) => void;
    setIsAntialiasApplied: (value: boolean) => void;
    setIsReLoadingLineGeoemtry : (value: boolean) => void;
    setMiniMapWells: (value: { [wellId: string]: VectorLayer<VectorSource> }) => void;
    setRangeTrace: (initial: number, end: number) => void;
    setRemoveWellInMiniMap: (layers: VectorLayer<VectorSource>[]) => void;
    setRestrict: (restrict: ISeismicRequirement) => void;
    setScaleX: (value: number) => void;
    setScaleY: (value: number) => void;
    setShowTraceHeaderTable: (value: boolean) => void;
    setSurveyMetadata: (value: ISurveyMetadata) => void;
    setSurveyMetadataOverlay: (value: ISurveyMetadata) => void;
    setSurveyMetadataSwipe: (value: ISurveyMetadata) => void;
    setSwipeValue: (value: number) => void;
    setVolumeToken: (value: string) => void;
    setVolumeTokenOverlay: (value: string) => void;
    setVolumeTokenSwipe: (value: string) => void;
    setWellHoverInfo: (value: IWellHoverInfo) => void;
    setWellRelatedData: (key: string, value: { layer: VectorLayer<VectorSource>, relatedData: IRelatedData, opened: boolean, images: IRelatedDataImages }) => void;
    setWellsLayers: (wellId: string, value: { [typeKey: string]: { layer: VectorLayer<VectorSource> | ImageLayer<ImageStatic>, checked: boolean, image?: IRelatedData, zoom?: Coordinate } }) => void;
    setWellRelatedDataTextLayers: (wellId: string, value: { [lineId: string]: VectorLayer<VectorSource<Point>> }) => void;
    setRedefineWellsLayers: () => void;
    showTraceHeaderTable: boolean;
    surveyMetadata: ISurveyMetadata | null;
    surveyMetadataOverlay: ISurveyMetadata | null;
    surveyMetadataSwipe: ISurveyMetadata | null;
    volumeToken: string | null;
    volumeIdentifier: string | null;
    setVolumeIdentifier: (value: string) => void;
    volumeTokenOverlay: string | null;
    volumeTokenSwipe: string | null;
    swipeValue: number;
    wellHoverInfo: IWellHoverInfo;
    wellRelatedData: { [wellId: string]: { layer: VectorLayer<VectorSource>, relatedData: IRelatedData, opened: boolean, images: IRelatedDataImages } };
    wellRelatedDataTextLayers: { [wellId: string]: { [lineId: string]: VectorLayer<VectorSource<Point>> } };
    wellsLayers: IWellLayers;
    isVerticallyInverted: boolean;
    setIsVerticallyInverted: (value: boolean) => void;
    useVelocityColorbars: boolean;
    setUseVelocityColorbars: (value: boolean) => void;
}

export interface IRangeTrace {
    initial: number;
    end: number;
}

export interface IScale {
    x: number;
    y: number;
}

export interface IAbsoluteGain {
    min: number;
    max: number;
}

export interface IRelatedDataImages {
    [key: string]: {
        open: boolean,
        title: string,
        file: string,
        coordinate: number[]
    }
}

export interface ILayer {
    [key: string]: VectorLayer<VectorSource>
}

export interface IWellHoverInfo {
    type: string,
    md: number[],
    rotativeDesk?: number,
    timeDepth?: string,
    date?: string,
    tvd?: number[],
    image?: string,
    imageArchiveName?: string,
    created: boolean
}

export interface IWellLayers {
    [wellKey: string]: {
        [typeKey: string]: {
            layer: VectorLayer<VectorSource> | ImageLayer<ImageStatic>,
            checked: boolean,
            zoom?: Coordinate
        }
    }
}

export interface IMiniMapWells {
    [wellKey: string]: VectorLayer<VectorSource>
}

export const useSeismicStore = create<ISeismicStore>()(
    (set, get) => ({
        navbarLoading: false,
        setNavbarLoading: (value: boolean) => set({ navbarLoading: value }),
        lines2D: null,
        calculator: null,
        createWellInMiniMap: [],
        colorbar: {
            Background: '#fff',
            Label: '',
            Name: '',
            Picture: '',
            Token: ''
        },
        gain: 0,
        hasErrorOnGeometry: false,
        genericCutsWithoutPermission: {},
        geometryLine: null,
        volumeToken: '',
        volumeTokenOverlay: '-',
        volumeTokenSwipe: '-',
        absoluteGain: {
            min: 0,
            max: 0
        },
        isLoading: true,
        isInverted: false,
        isAntialiasApplied: false,
        isReLoadingLineGeometry: false,
        miniMapWells: {},
        rangeTrace: {
            initial: 1,
            end: 1000
        },
        removeWellInMiniMap: [],
        resetWellRelatedData: () => set({ wellRelatedData: {} }),
        resetWellRelatedDataTextLayers: () => set({ wellRelatedDataTextLayers: {} }),
        restrict: null,
        setRestrict: (restrict: ISeismicRequirement) => set({ restrict: restrict }),
        scale: {
            x: 1,
            y: 1
        },
        swipeValue: 50,
        setLines2D: (value: Survey2DLines[]) => set({
            lines2D: value
        }),
        setCalculator: (value: SeismicCalculator) => set({
            calculator: value
        }),
        setColorbar: (value: IColorbar) => set({
            colorbar: value
        }),
        setCreateWellInMiniMap: (layer: VectorLayer<VectorSource>[]) => set({ createWellInMiniMap: layer }),
        setDefineWellLayers: (value: IWellLayers) => set({ wellsLayers: value }),
        setGain: (value: number) => set({
            gain: value
        }),
        setGenericCutsWithoutPermission: (value: { [wellId: string]: string[] }) => set({ genericCutsWithoutPermission: { ...get().genericCutsWithoutPermission, ...value } }),
        setAbsoluteGain: (value: IAbsoluteGain) => set({
            absoluteGain: value
        }),
        setIsLoading: (loading: boolean) => set({ isLoading: loading }),
        setHasErrorOnGeometry: (error: boolean) => set({ hasErrorOnGeometry: error }),
        setIsInverted: (inverted: boolean) => set({ isInverted: inverted }),
        setIsAntialiasApplied: (apply: boolean) => set({ isAntialiasApplied: apply }),
        setIsReLoadingLineGeoemtry: (value: boolean) => set({ isReLoadingLineGeometry: value }),
        setRangeTrace: (initial: number, end: number) => set({
            rangeTrace: {
                initial: initial,
                end: end
            }
        }),
        setMiniMapWells: (value: { [wellId: string]: VectorLayer<VectorSource> }) => set({ miniMapWells: { ...get().miniMapWells, ...value } }),
        setGeometryLine: (geometryLine) => set({
            geometryLine: geometryLine
        }),
        setRemoveWellInMiniMap: (layers: VectorLayer<VectorSource>[]) => set({ removeWellInMiniMap: layers }),
        setScaleX: (value: number) => set({
            scale: {
                ...get().scale,
                x: value
            }
        }),
        setScaleY: (value: number) => set({
            scale: {
                ...get().scale,
                y: value
            }
        }),
        setSurveyMetadata: (value: ISurveyMetadata) => set({ surveyMetadata: value }),
        setSurveyMetadataSwipe: (value: ISurveyMetadata) => set({ surveyMetadataSwipe: value }),
        setSurveyMetadataOverlay: (value: ISurveyMetadata) => set({ surveyMetadataOverlay: value }),
        volumeIdentifier: null,
        setVolumeIdentifier: (value: string) => set({ volumeIdentifier: value }),
        setVolumeToken: (value: string) => set({ volumeToken: value }),
        setVolumeTokenOverlay: (value: string) => set({ volumeTokenOverlay: value }),
        setVolumeTokenSwipe: (value: string) => set({ volumeTokenSwipe: value }),
        setShowTraceHeaderTable: (show: boolean) => set({ showTraceHeaderTable: show }),
        setWellHoverInfo: (value: IWellHoverInfo) => set({ wellHoverInfo: value }),
        setWellRelatedData: (key: string, value: { layer: VectorLayer<VectorSource>, relatedData: IRelatedData, opened: boolean, images: IRelatedDataImages }) => set({ wellRelatedData: { ...get().wellRelatedData, [key]: value } }),
        setWellRelatedDataTextLayers: (wellId: string, value: { [lineId: string]: VectorLayer<VectorSource<Point>> }) => {
            const { wellRelatedDataTextLayers } = get();
            const updateRelatedText = {
                ...wellRelatedDataTextLayers,
                [wellId]: {
                    ...wellRelatedDataTextLayers[wellId],
                    ...value
                }
            };

            set({ wellRelatedDataTextLayers: updateRelatedText });
        },
        setWellsLayers: (wellId: string, value: { [typeKey: string]: { layer: VectorLayer<VectorSource> | ImageLayer<ImageStatic>, checked: boolean, image?: IRelatedData, zoom?: Coordinate } }) => {
            const { wellsLayers } = get();
            const updatedWellsLayers = {
                ...wellsLayers,
                [wellId]: {
                    ...wellsLayers[wellId],
                    ...value
                },
            };

            set({ wellsLayers: updatedWellsLayers });
        },
        setRedefineWellsLayers: () => set({}),
        showTraceHeaderTable: false,
        surveyMetadata: null,
        surveyMetadataSwipe: null,
        setSwipeValue: (value: number) => set({ swipeValue: value }),
        wellHoverInfo: { type: '', md: [0], created: false },
        wellRelatedData: {},
        wellRelatedDataTextLayers: {},
        surveyMetadataOverlay: null,
        currentSeismicLine: null,
        wellsLayers: {},
        isVerticallyInverted: false,
        setIsVerticallyInverted: (value) => set({ isVerticallyInverted: value }),
        useVelocityColorbars: false,
        setUseVelocityColorbars: (value) => set({ useVelocityColorbars: value }),
    })
);